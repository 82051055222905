import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Response, Role } from '../../core/models';


@Injectable()
export class RoleService {

    constructor(
        private httpClient : HttpClient
    ) { };

    //--- api methods ---//
    public getRoles() : Observable<Response>{
        return this.httpClient.get(`api/v1/roles`);
    };

    public getRole(roleCode : string) : Observable<Response>{
        const fromObject = {
            code : roleCode
        };
        const params = new HttpParams({ fromObject });
        return this.httpClient.get(`api/v1/roles`, { params });
    };

    public setRole(role : Role) : Observable<Response>{
        if(role.id){
            return this.httpClient.put(`api/v1/roles/${role.role_code}`, role);
        }
        return this.httpClient.post(`api/v1/roles`, role);
    };

    public deleteRole(roleCode : string) : Observable<Response>{
        return this.httpClient.delete(`api/v1/roles/${roleCode}`);
    };

}
