import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { UtilsService } from '../utils/utils.service';
import { UserType } from '../../core/enums';
import { Store } from '@ngrx/store';
import { getLoginProfileInAuthenticateState, State } from '../../stores/reducers';

@Injectable({
    providedIn: "root",
})
export class UserService {

    constructor(
        private http: HttpClient,
        private utilsService: UtilsService,
        private store: Store<State>,
    ) { };

    getUserProfile() {
        return this.store.select(getLoginProfileInAuthenticateState);
    }

    //--- api methods ---//
    public searchUsers(options?: {
        email? : string;
        types? : Array<UserType>;
        status? : any;
    }) {
        // const fromObject = this.utilsService.object.clone({
        //     email: options?.email || undefined,
        //     types : options?.types?.join(',') || undefined,
        //     status : options?.status || undefined
        // });
        // const params = new HttpParams({ fromObject });
        // return this.httpClient.get(`api/v1/users/search`, { params });
    };

    // public searchActiveUsers(options?: {
    //     email? : string;
    //     types? : Array<UserType>
    // }): Observable<Response> {
    //     return this.searchUsers({ ...(options || {}), status : [2, 3, 8] });
    // };

    // public searchDeActiveUsers(options?: {
    //     email? : string;
    //     types? : Array<UserType>
    // }): Observable<Response> {
    //     return this.searchUsers({ ...(options || {}) });
    // };

    // public getUsers(args? : Array<string>|{
    //     types? : Array<UserType>;
    //     email : string;
    //     name : string;
    // }) : Observable<Response>{
    //     if(Array.isArray(args)){
    //         return this.httpClient.post(`api/v1/users/get-users`, { user_ids : args });
    //     }

    //     const fromObject = this.utilsService.object.clone({
    //         types : args?.types?.join(',') ?? undefined,
    //         name: args?.name,
    //         email: args?.email,
    //     });
    //     const params = new HttpParams({ fromObject });
    //     return this.httpClient.get(`api/v1/users`, { params });
    // };

    // public getUser(userId : string) : Observable<Response>{
    //     const fromObject = {
    //         user_id: userId
    //     };
    //     const params = new HttpParams({ fromObject });
    //     return this.httpClient.get(`api/v1/users`, { params });
    // };

    // public createUsers(user : User): Observable<Response> {
    //     return this.httpClient.post(`api/v1/users/create-user`, user);
    // };

    // public getEnterpriseRoles() : Observable<Response>{
    //     return this.httpClient.get(`api/v1/users/get-enterprises`);
    // };

    // public getGroupAssignUser(userId : string, groupRoleId : string) : Observable<Response>{
    //     const fromObject = {
    //         user_id: userId,
    //         group_role_id: groupRoleId
    //     };
    //     const params = new HttpParams({ fromObject });
    //     return this.httpClient.get(`api/v1/users/get-groupassignuser`, { params });
    // };

    // public getAdvisor(userId : string, departmentId : number) : Observable<Response>{
    //     const fromObject = {
    //         user_id: userId,
    //         department_id: departmentId
    //     };
    //     const params = new HttpParams({ fromObject });
    //     return this.httpClient.get(`api/v1/users/get-advisor`, { params });
    // };

}
