import { createAction, props } from '@ngrx/store';
import { ERoleUser } from '../../../pages/user-role-create/user.role';

export enum ERolesAccess {
  LOAD = '[roles] load roles',
}

export const actionRolesAccess = createAction(
  ERolesAccess.LOAD,
  props<{
    isSuperUser: boolean;
    permissions: Array<ERoleUser>;
  }>()
);

export const rolesAction = {
  actionRolesAccess
};