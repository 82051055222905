import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { IApiResponse } from '../../core/models';
import { Router } from '@angular/router';


declare const $ : any;

@Injectable({
    providedIn: 'root',
})
export class UtilsService {

    constructor(
        private router: Router
    ) { }

    handleResponse<T = unknown>(
        respone: IApiResponse<T>,
        isVerifyToken: boolean = true
      ) {
        if (respone.meta.response_code == '20000') {
          return respone?.data ?? null;
        } else {
          // if (typeof respone.meta.response_desc == 'string') {
          //   this.notification.error('แจ้งเตือน', respone.meta.response_desc);
          //   return null;
          // }
          // this.notification.error('Warning', 'message in object');
          // return respone.meta.response_desc;
          return null;
        }
      }

    // //--- private methods ---//
    // private _dialog = {
    //     config : {
    //         allowOutsideClick : false,
    //         reverseButtons : true,
    //         confirmButtonColor: "#3085d6",
    //         cancelButtonColor: "#d33"
    //     },
    //     alert : async (title : string, text : string, config? : {
    //         icon? : SweetAlertIcon;
    //         confirmButtonText?: string;
    //     }) : Promise<boolean> => {
    //         const isHTML = /^<([\w-]*).*(<\/\1>)$/s.test(text?.trim());
    //         const alertConfing : SweetAlertOptions = {
    //             ...this._dialog.config,
    //             title : title,
    //             [isHTML ? 'html' : 'text'] : text,
    //             icon : 'info'
    //         };
    //         for(const property in (config || {})){
    //             if(config[property] != null){
    //                 alertConfing[property] = config[property];
    //             }
    //         }
    //         const result = await Swal.fire(alertConfing);
    //         return <boolean>result?.value || false;
    //     },
    //     success : async (config? : {
    //         title :string;
    //         text :string;
    //     }) : Promise<boolean> => {
    //         return await this._dialog.alert(config?.title || 'Successfully', config?.text, {
    //             icon: 'success'
    //         });
    //     },
    //     warning : async (text : string, config? : {
    //         title : string;
    //     }) : Promise<boolean> => {
    //         return await this._dialog.alert(config?.title || 'Warning', text, {
    //             icon: 'warning'
    //         });
    //     },
    //     error : async (text : string, config? : {
    //         title : string;
    //         confirmButtonText?: string;
    //     }) : Promise<boolean> => {
    //         return await this._dialog.alert(config?.title || 'Error', text, {
    //             icon: 'error',
    //             confirmButtonText: config?.confirmButtonText
    //         });
    //     },
    //     confirm : async (title : string, text : string, config? : {
    //         icon? : SweetAlertIcon;
    //         confirmButtonText?: string;
    //         cancelButtonText?: string;
    //     }) : Promise<boolean> => {
    //         const isHTML = /^<([\w-]*).*(<\/\1>)$/s.test(text?.trim());
    //         const alertConfing : SweetAlertOptions = {
    //             ...this._dialog.config,
    //             title : title,
    //             [isHTML ? 'html' : 'text'] : text,
    //             icon : config?.icon ?? 'question',
    //             showCancelButton : true,
    //             cancelButtonText : config?.cancelButtonText || 'Cancel',
    //             confirmButtonText : config?.confirmButtonText || 'OK'
    //         };
    //         const result = await Swal.fire(alertConfing);
    //         return <boolean>result?.value || false;
    //     },
    //     delete : async (config? : {
    //         title :string;
    //         text :string;
    //     }) : Promise<boolean> => {
    //         return await this._dialog.confirm(
    //             config?.title || 'Are you sure?',
    //             config?.text || "You won't be able to revert this!"
    //         );
    //     },
    //     timeoutDialog : async (title: string, text: string, second: number, config?: {
    //         icon? : SweetAlertIcon;
    //         confirmButtonText?: string;
    //         cancelButtonText?: string;
    //     }) : Promise<{
    //         isConfirmed: boolean,
    //         isTimeout: boolean
    //     }> => {

    //         let offInterval = null;

    //         const timerTemplate = `
    //             <div style="margin:24px 0px;font-size:1.5em;">
    //                 <span name="timer">${getDisplayTimer(second)}</span>
    //             </div>
    //         `;
    //         const template = `
    //             <div>
    //                 <div>
    //                     <span>${text}</span>
    //                 </div>
    //                 ${timerTemplate}
    //             </div>
    //         `;

    //         function convertSecondToDDHHMMSS(second: number): {
    //             days: number,
    //             hours: number,
    //             minutes: number,
    //             seconds: number
    //         } {
    //             const obj = {
    //                 days: 0,
    //                 hours: 0,
    //                 minutes: 0,
    //                 seconds: 0
    //             };
    //             obj.seconds = second % 60;
    //             obj.minutes = Math.floor(second / 60) % 60;
    //             obj.hours = Math.floor(second / 60 / 60) % 24;
    //             obj.days = Math.floor(second / 60 / 60 / 24);
    //             return obj;
    //         };

    //         function getDisplayTimer(second: number): string {
    //             const obj = convertSecondToDDHHMMSS(second);
    //             const units = ['mins', 'hrs', 'days'];
    //             const times = [];

    //             if (obj.days > 0) {
    //                 times.push(obj.days);
    //                 times.push(obj.hours);
    //             }
    //             else if (obj.hours > 0) {
    //                 times.push(obj.hours);
    //             }
    //             times.push(obj.minutes);
    //             times.push(obj.seconds);

    //             let timeString = times.map(time => {
    //                 if (time < 10) {
    //                     time = `0${time}`;
    //                 }
    //                 return `${time}`;
    //             }).join(':');

    //             timeString = `${timeString} ${units[times.length - 2]}`;
    //             return timeString;
    //         };

    //         function stopTimer(): void {
    //             if (offInterval != null) {
    //                 clearInterval(offInterval)
    //                 offInterval = null;
    //             }
    //         };

    //         function startTimer(): void {
    //             stopTimer();
    //             offInterval = setInterval(() => {
    //                 const content = Swal.getContent();
    //                 if (content) {
    //                     const timerEle = content.querySelector(`[name="timer"]`);
    //                     if (timerEle) {
    //                         const remaining = Math.round(Swal.getTimerLeft() / 1000);
    //                         timerEle.textContent = getDisplayTimer(remaining);
    //                     }
    //                 }
    //             }, 1000);
    //         };

    //         const alertConfing : SweetAlertOptions = {
    //             ...this._dialog.config,
    //             title : title,
    //             html: template,
    //             timer: second * 1000,
    //             showCancelButton : true,
    //             icon : 'question',
    //             onOpen: () => { startTimer(); },
    //             onClose: () => { stopTimer(); },
    //             ...config
    //         };

    //         const result = await Swal.fire(alertConfing);
    //         return {
    //             isConfirmed: result.isConfirmed,
    //             isTimeout: result.dismiss == Swal.DismissReason.timer
    //         };
    //     },
    //     confirmWithButton : async (title : string, text : string, buttons : Array<{ title : string; value : any; backgroundColor? : string }>, config? : {
    //         icon? : SweetAlertIcon;
    //     }) : Promise<any> => {
    //         const isHTML = /^<([\w-]*).*(<\/\1>)$/s.test(text?.trim());

    //         const defaultAlertConfig = {
    //             showConfirmButton: false,
    //             showCancelButton: false,
    //             onOpen : () => {
    //                 const actionsEle = Swal.getActions();
    //                 actionsEle.style.display = null;
    //                 let lastButtonEle = null;
    //                 for(const button of buttons){
    //                     const buttonEle = document.createElement('button');
    //                     buttonEle.setAttribute("name",button.value);
    //                     buttonEle.setAttribute("type","button");
    //                     buttonEle.classList.add('swal2-confirm', 'swal2-styled');
    //                     buttonEle.textContent = button.title;
    //                     if(button.backgroundColor){
    //                         buttonEle.style.backgroundColor = button.backgroundColor;
    //                     }
    //                     else if(button.value == "cancel"){
    //                         buttonEle.style.backgroundColor = "rgb(221, 51, 51)";
    //                     }
    //                     buttonEle.addEventListener('click', ()=> {
    //                         Swal.close({
    //                             isConfirmed : true,
    //                             isDenied: false,
    //                             isDismissed : false,
    //                             value : button.value
    //                         })
    //                     });
    //                     actionsEle.appendChild(buttonEle);
    //                     lastButtonEle = buttonEle;
    //                 }
    //                 lastButtonEle.focus();
    //             },
    //             onClose : () => {
    //                 const actionsEle = Swal.getActions();
    //                 for(const button of buttons){
    //                     const buttonEle = actionsEle.querySelector(`[name="${button.value}"]`);
    //                     buttonEle.removeEventListener('click', null);
    //                 }
    //             }
    //         };

    //         const alertConfing : SweetAlertOptions = {
    //             ...this._dialog.config,
    //             title : title,
    //             [isHTML ? 'html' : 'text'] : text,
    //             icon : 'question',
    //             showCancelButton : true,
    //             ...defaultAlertConfig
    //         };
    //         const result = await Swal.fire(alertConfing);
    //         return <boolean>result?.value || false;
    //     },
    // };

    // private _toast = {
    //     createToastContainerEle : () : HTMLElement => {
    //         let containerEle = this._toast.getToastContainerEle();
    //         if(!containerEle){
    //             containerEle = document.createElement('div');
    //             containerEle.classList.add('toast-container');
    //             containerEle.setAttribute('style',[
    //                 'position: fixed',
    //                 'bottom: 0px',
    //                 'right: 0px',
    //                 'padding : 16px',
    //                 'z-index: 10'
    //             ].join(';'));
    //             document.body.appendChild(containerEle);
    //         }
    //         return containerEle;
    //     },
    //     getToastContainerEle : () : HTMLElement => {
    //         const containerEle = document.querySelector('body > .toast-container') as HTMLElement;
    //         return containerEle;
    //     },
    //     createToastEle : (title : string, text : string) : HTMLElement => {
    //         let containerEle = this._toast.createToastContainerEle();
    //         const toastEle = document.createElement('div');
    //         const toastHeaderEle = document.createElement('div');
    //         const toastHeaderContentEle = document.createElement('span');
    //         const toastBodyEle = document.createElement('div');
    //         const toastBodyContentEle = document.createElement('span');
    //         toastEle.classList.add('toast', 'hide');
    //         toastHeaderEle.classList.add('toast-header');
    //         toastBodyEle.classList.add('toast-body');
    //         toastEle.appendChild(toastHeaderEle);
    //         toastEle.appendChild(toastBodyEle);
    //         toastHeaderEle.appendChild(toastHeaderContentEle);
    //         toastBodyEle.appendChild(toastBodyContentEle);
    //         toastHeaderContentEle.append(title);
    //         toastBodyContentEle.append(text);
    //         containerEle.appendChild(toastEle);
    //         return toastEle;
    //     },
    //     alert : (title : string, text : string, config? : {
    //         delay? : number;
    //         autohide? : boolean;
    //     }) => {
    //         const toastEle = this._toast.createToastEle(title, text);
    //         $(toastEle).toast({ delay : 1000, ...config});
    //         $(toastEle).toast('show');
    //         $(toastEle).one('hidden.bs.toast', async () => {
    //             toastEle.remove();
    //         });
    //     }
    // };

    // private _response = {
    //     verify : (response : Response): {
    //         success: boolean;
    //         isAuthentication : boolean;
    //         warning: boolean;
    //     } => {
    //         const typeCode = (((response?.meta?.response_code || 0) / 10000) | 0);

    //         return {
    //             success : typeCode == 2,
    //             isAuthentication: typeCode != 1,
    //             warning : typeCode == 4
    //         };
    //     },
    //     alert : async (response : Response) : Promise<boolean> => {
    //         const verifyResponse = this._response.verify(response);
    //         if(verifyResponse.success){
    //             return await this._dialog.success();
    //         }
    //         else {
    //             let template = response?.meta?.response_desc;
    //             if(!!response?.error_detail?.error_stack){
    //                 const now = new Date();
    //                 template = `<div>${template}</div>`;
    //                 if(response.meta?.response_ref){
    //                     template = `
    //                         ${template}
    //                         <div class="mt-5">(Ref : ${response.meta.response_ref})</div>
    //                     `;
    //                 }
    //                 template = `${template}<div class="mt-3" style="font-size: 14px;">Timestamp : ${now.toLocaleDateString()} ${now.toLocaleTimeString()}</div>`;
    //                 if(window.location.host.indexOf('localhost') != -1){
    //                     template = `
    //                         ${template}
    //                         <div class="mt-4">
    //                             <a class="font14" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
    //                                 <i class="fa fa-chevron-circle-down" style="color:#f27474"></i>
    //                             </a>
    //                         </div>
    //                         <div class="collapse" id="collapseExample">
    //                             <div class="card-body error-detail pt-4" style="padding:0px 16px;">
    //                                 <script type="text/plain" style="display: block;">
    //                                     (${response.error_detail.error_code}) ${response.error_detail.error_stack}, ${response.error_detail.error_file}, ${response.error_detail.error_line}
    //                                 </script>
    //                             </div>
    //                         </div>
    //                     `;
    //                 }
    //             }

    //             if(verifyResponse.warning){
    //                 return await this._dialog.warning(template);
    //             }

    //             return await this._dialog.error(template);
    //         }
    //     }
    // };

    // private _splashScreen = {
    //     percentageLoading : 0,
    //     offIntervalLoadingProgress : null,
    //     startLoadingProgress : async (stopPercentage: number, options?: {
    //         text? : string,
    //         timeInterval?: number,
    //         slowPercentage?: number,
    //         completedPercentage?: number,
    //         resolvePrecentage?: number
    //     }) : Promise<void> => {
    //         return new Promise(resolve => {
    //             const timeInterval = options?.timeInterval || 100;
    //             const slowPercentage = options?.slowPercentage;
    //             const completedPercentage = options?.completedPercentage || 100;
    //             const resolvePrecentage = options?.resolvePrecentage || completedPercentage;
    //             this._splashScreen.stopLoadingProgress();
    //             this._splashScreen.offIntervalLoadingProgress = setInterval(() => {
    //                 const percentageLoading = this._splashScreen.percentageLoading + 1;
    //                 this._splashScreen.setLoadingProgress(percentageLoading, options?.text);
    //                 if (percentageLoading == slowPercentage) {
    //                     this._splashScreen.startLoadingProgress(stopPercentage, {
    //                         text : options?.text,
    //                         timeInterval: 300
    //                     });
    //                 }
    //                 if (percentageLoading >= stopPercentage) {
    //                     this._splashScreen.stopLoadingProgress();
    //                 }
    //                 if (percentageLoading >= completedPercentage) {
    //                     this._splashScreen.stopLoadingProgress();
    //                 };
    //                 if (percentageLoading >= resolvePrecentage) {
    //                     setTimeout(resolve, 500);
    //                 }
    //             }, timeInterval);
    //         });
    //     },
    //     setLoadingProgress : (percentage: number, text? : string) : void => {
    //         text = text ?? 'Loading';
    //         this._splashScreen.percentageLoading = percentage;
    //         const progressBarEle = document.querySelector('.splash-screen-progress-bar .progress-bar') as HTMLElement;
    //         const progressTextValueEle = document.querySelector('.splash-screen-progress-bar .progress-bar-text-value');
    //         const progressTextEle = document.querySelector('.splash-screen-progress-bar .progress-bar-text');
    //         percentage = percentage > 100 && 100 || percentage > 0 && percentage || 0;
    //         progressBarEle.style.width = `${percentage}%`;
    //         progressTextValueEle.innerHTML = `${percentage}%`;
    //         progressTextEle.innerHTML = `${text}...`;
    //     },
    //     stopLoadingProgress : () : void => {
    //         if (this._splashScreen.offIntervalLoadingProgress != null) {
    //             clearInterval(this._splashScreen.offIntervalLoadingProgress);
    //             this._splashScreen.offIntervalLoadingProgress = null;
    //         }
    //     }
    // };

    // private _guid = {
    //     new: () => {
    //         return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    //             const random = Math.random() * 16 | 0;
    //             const value = c == 'x' ? random : (random & 0x3 | 0x8);
    //             return value.toString(16);
    //         });
    //     }
    // };

    // private _object ={
    //     clone: (object: any) => {
    //         if (!object) {
    //             return object;
    //         }
    //         return JSON.parse(JSON.stringify(object));
    //     },
    //     get: (object: object, properties: string): any => {
    //         let value = undefined;
    //         if (typeof (properties) != "string" || properties.trim().length == 0) {
    //             return object;
    //         }
    //         if (object != null && typeof (object) == "object" && !Array.isArray(object)) {
    //             let props = properties.split('.') || [];
    //             value = object;
    //             for (const prop of props) {
    //                 value = (value || {})[prop];
    //             }
    //         }
    //         return value;
    //     },
    //     set : (object: object, properties: string, value : any) : void => {
    //         if (typeof (properties) != "string" || properties.trim().length == 0) {
    //             return;
    //         }
    //         if (object != null && typeof (object) == "object" && !Array.isArray(object)) {
    //             let props = properties.split('.') || [];
    //             const lastProp = props.pop();
    //             let variable = object;
    //             for (const prop of props) {
    //                 if(prop in (variable || {})){
    //                     variable = variable[prop];
    //                 }
    //             }
    //             if(lastProp && variable){
    //                 variable[lastProp] = value;
    //             }
    //         }
    //     },
    //     merge: (objectA: object, objectB: object): any => {
    //         let objA = JSON.parse(JSON.stringify(objectA || {})) || {};
    //         let objB = JSON.parse(JSON.stringify(objectB || {})) || {};
    //         for (let i in objA) {
    //             if (i in objB) {
    //                 if (objB[i] == 'undefined$') {
    //                     delete objA[i];
    //                 }
    //                 else if (Array.isArray(objA[i]) && Array.isArray(objB[i])) {
    //                     objA[i] = objB[i];
    //                 }
    //                 else if (!!objA[i] && typeof (objA[i]) == "object" && !!objB[i] && typeof (objB[i]) == "object") {
    //                     objA[i] = this._object.merge(objA[i], objB[i]);
    //                 }
    //                 else {
    //                     objA[i] = objB[i];
    //                 }
    //             }
    //         }
    //         for (let i in objB) {
    //             if (!(i in objA) && objB[i] != 'undefined$') {
    //                 objA[i] = objB[i];
    //             }
    //         }
    //         return objA;
    //     }
    // };

    // private _datetime = {
    //     config : {
    //         format : null
    //     },
    //     setFormat : (format : string) : void => {
    //         this._datetime.config.format = format;
    //     },
    //     displayedDatetime : (datetime : Date) : string => {
    //         return this.datePipe.transform(datetime, this._datetime.config.format);
    //     },
    //     remainingDatetime : (datetime : Date) : {
    //         remaining : {
    //             year : number;
    //             month : number;
    //             day : number;
    //             hour : number;
    //             minute : number;
    //             second : number;
    //         };
    //         total : {
    //             year : number;
    //             month : number;
    //             day : number;
    //             hour : number;
    //             minute : number;
    //             second : number;
    //         };
    //         toString : () => string;
    //     } => {
    //         let text = "";
    //         let second = ((datetime?.getTime() || 0) - (new Date()).getTime()) / 1000;
    //         second = second > 0 && second || 0;
    //         const secondPerUnit = {
    //             year : 365 * 24 * 60 * 60, month : 30 * 24 * 60 * 60,
    //             day : 24 * 60 * 60, hour : 60 * 60, minute : 60
    //         };
    //         const displayedUnit = {
    //             year : 'year', month : 'month', day : 'day',
    //             hour : 'hr', minute : 'min', second : 'sec'
    //         };
    //         const total = {
    //             year : (second/secondPerUnit.year) | 0,
    //             month : (second/secondPerUnit.month) | 0,
    //             day : (second/secondPerUnit.day) | 0,
    //             hour : (second/secondPerUnit.hour) | 0,
    //             minute : (second/secondPerUnit.minute) | 0,
    //             second : second | 0
    //         };
    //         const remaining = {
    //             year : total.year,
    //             month : total.month % 12,
    //             day : total.day % 30,
    //             hour : total.hour % 24,
    //             minute : total.minute % 60,
    //             second : total.second % 60
    //         };
    //         for(const unit in total){
    //             const value = total[unit];
    //             if(value){
    //                 text = `${value} ${displayedUnit[unit]}${value > 1 ? 's' : ''}`
    //                 break;
    //             }
    //         }
    //         return {
    //             remaining : remaining,
    //             total : total,
    //             toString : () : string => {
    //                 return `${text || '0 sec'} left`;
    //             }
    //         };
    //     },
    //     prettyDatetime : (datetime: Date) : {
    //         toString : () => string;
    //     } => {
    //         let text = "";
    //         let second = ((new Date()).getTime() - (datetime?.getTime() || 0)) / 1000;
    //         second = second > 0 && second || 0;
    //         const secondPerUnit = {
    //             year : 365 * 24 * 60 * 60, month : 30 * 24 * 60 * 60,
    //             day : 24 * 60 * 60, hour : 60 * 60, minute : 60
    //         };
    //         const displayedUnit = {
    //             year : 'year', month : 'month', day : 'day',
    //             hour : 'hr', minute : 'min', second : 'sec'
    //         };
    //         const total = {
    //             year : (second/secondPerUnit.year) | 0,
    //             month : (second/secondPerUnit.month) | 0,
    //             day : (second/secondPerUnit.day) | 0,
    //             hour : (second/secondPerUnit.hour) | 0,
    //             minute : (second/secondPerUnit.minute) | 0,
    //             second : second | 0
    //         };
    //         for(const unit in total){
    //             const value = total[unit];
    //             if(value){
    //                 text = `${value} ${displayedUnit[unit]}${value > 1 ? 's' : ''} ago`
    //                 break;
    //             }
    //         }
    //         return {
    //             toString : () : string => {
    //                 return `${text || 'now'}`;
    //             }
    //         }
    //     }
    // };

    // private _validator = {
    //     email : (email : string) : boolean => {
    //         const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //         return regex.test(email);
    //     },
    //     guid : (guid : string) : boolean => {
    //         const regex = /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/;
    //         return regex.test(guid);
    //     }
    // };

    // //--- public methods ---//
    // public dialog = this._dialog;
    // public toast = this._toast;
    // public response = this._response;
    // public splashScreen = this._splashScreen;
    // public guid = this._guid;
    // public object = this._object;
    // public datetime = this._datetime;
    // public validator = this._validator;

    // public async sleep(millisecond: number): Promise<void> {
    //     return new Promise(resolve => {
    //         setTimeout((): void => { resolve() }, millisecond);
    //     });
    // };

}
