import { createReducer, on } from '@ngrx/store';
import { actionRolesAccess } from '../../actions';
import { ERoleUser } from '../../../pages/user-role-create/user.role';

export interface IRolesUserAccess<T = unknown> {
  isSuperUser: boolean;
  permissions: Array<ERoleUser>;
}

export const initialStateData: IRolesUserAccess = {
  isSuperUser: false,
  permissions: [],
};

export const rolesAccessReducer = createReducer<IRolesUserAccess>(
  initialStateData,
  on(actionRolesAccess, (state, payload) => {
    return {
      ...state,
      isSuperUser: payload.isSuperUser,
      permissions: payload.permissions,
    };
  }),
);
