import { createReducer, on } from '@ngrx/store';
import { routerAction } from '../../actions';

export const routerFeatureKey = 'router';

export interface RouterState {
    configs: any;
}

export const initialState: RouterState = {
    configs: {}
};

export const RouterReducer = createReducer(
    initialState,
    on(
        routerAction.setConfigsInStore,
        (state, action) => {
            const configs = { ...state.configs };
            configs[action.payload.url] = action.payload.config;
            if (!configs[action.payload.url]) {
                delete configs[action.payload.url];
            }
            return {
                ...state,
                configs: configs
            };
        }
    )
);

