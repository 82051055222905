import { createReducer, on } from '@ngrx/store';
import { actionLoadMedia, actionSetupDirectoryMedia, actionSetupMediaRoot, IDirectoryTree } from '../../actions';

export interface IMediaData<T = unknown> {
  isLoad: boolean;
  data: T;
  root: any;
  directory: IDirectoryTree[] | null;
}

export const initialStateData: IMediaData = {
  isLoad: false,
  data: null,
  root: null,
  directory: null
};

export const mediaReducer = createReducer<IMediaData>(
  initialStateData,
  on(actionLoadMedia, (state, payload) => {
    return {
      ...state,
      isLoad: true,
      data: payload.data.result,
    };
  }),
  on(actionSetupMediaRoot, (state, payload) => {
    return {
      ...state,
      root: payload.root,
    };
  }),
  on(actionSetupDirectoryMedia, (state, payload) => {
    return {
      ...state,
      directory: payload.setup,
    };
  }),
);
