import { Injectable, OnDestroy } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { interval, Observable, of, Subscription, take } from "rxjs";
import { map, takeWhile, tap } from "rxjs/operators";
import { CookieService } from "ngx-cookie-service";

import { UtilsService } from "../utils/utils.service";
import { IApiResponse, Role } from "../../core/models";
import { EnvHelper } from "../../helpers/env.helper";

export interface IVerifyCodeResponse {
  cross_origin: boolean;
  access_token: string;
  id_token: string;
  refresh_token: string;
  session_expire: number | string;
}

@Injectable({
  providedIn: "root",
})
export class AuthenticateService {
  constructor(
    private http: HttpClient,
    private utilsService: UtilsService,
    private cookieService: CookieService // private router : Router, // private store: Store,
  ) // private cookieService: CookieService,
  // private utilsService : UtilsService
  {
    //this._contructorComponent();
  }

  setDevMode() {
    this.cookieService.set('devMode', 'yes');
    return this.getDevMode(); 
  }

  getDevMode() {
    return this.cookieService.get('devMode') === 'yes';
  }

  //--- api methods ---//
  getUserProfile() {
    return this.http
      .get<IApiResponse>(`users/info`)
      .pipe(map((res) => this.utilsService.handleResponse(res)));
  }

  getAuthenticateLoginURL() {
    return this.http
      .get<IApiResponse<{ isMock: boolean; url: any }>>("authentication")
      .pipe(map((res) => this.utilsService.handleResponse(res)));
  }

  verifyAuthenticateCode(verifyCode: string) {
    return this.http
      .get<IApiResponse<IVerifyCodeResponse>>(`verify-code/${verifyCode}`)
      .pipe(
        map((res) => this.utilsService.handleResponse(res)),
        map((res) => this.setupCookieOnCrossOrigin(res))
      );
  }

  refreshToken() {
    return this.http
      .get<IApiResponse<IVerifyCodeResponse>>(`refresh-token`)
      .pipe(
        map((res) => this.utilsService.handleResponse(res)),
        map((res) => this.setupCookieOnCrossOrigin(res))
      );
  }

  setupMockUserOkta(body: {
    username: string;
    password: string;
  }) {
    return this.http
      .post<IApiResponse<IVerifyCodeResponse>>(`mock-okta`, body)
      .pipe(
        map((res) => this.utilsService.handleResponse(res)),
        map((res) => this.setupCookieOnCrossOrigin(res))
      );
  }

  getLogout() {
    const id_token = this.cookieService.get('id_token');
    return this.http
      .get<IApiResponse<{ url: any }>>("logout", {
        params: {
          id_token: id_token
        }
      })
      .pipe(map((res) => {
        this.cookieService.deleteAll();
        return this.utilsService.handleResponse(res)
      }));
  }
  //---
  setupCookieOnCrossOrigin(data: IVerifyCodeResponse | null) {
    if (data && data?.cross_origin === true) {
      for (const [key, value] of Object.entries(data)) {
        this.cookieService.set(key, value, undefined, "/");
      }
    }
    return data;
  }

  checkTimeoutSessionExpire() {
    if (!this.cookieService.check('session_expire')) {
      return false;
    }

    const expireEpoch = parseInt(this.cookieService.get('session_expire')) || 0;
    const nowEpoch = Math.floor(Date.now() / 1000);
    const remainingTime = expireEpoch - nowEpoch;
    return remainingTime > 0;
  }

  getRemainingTime() {
    const isSessionExpired = this.checkTimeoutSessionExpire();
    if(isSessionExpired === true) {
      const expireEpoch = parseInt(this.cookieService.get('session_expire')) || 0;
      return interval(1000).pipe(
        map(take => {
          const nowEpoch = Math.floor(Date.now() / 1000);
          // console.log('expireEpoch --> ', {
          //   take,
          //   nowEpoch,
          //   expireEpoch
          // });
          return expireEpoch - nowEpoch
        })
      )
    }

    return of(0);
  }

  getLimitTimeRemaining() {
    const isSessionExpired = this.checkTimeoutSessionExpire();
    if(isSessionExpired === true) {
      const expireEpoch = parseInt(this.cookieService.get('session_expire')) || 0;
      const nowEpoch = Math.floor(Date.now() / 1000);
      return expireEpoch - nowEpoch;
    }
    return 0
  }

  getActionRefreshToken() {
    const limit = Number(localStorage.getItem('action_refresh_token') ?? 0) + 1;

    return limit;
  }

  setActionRefreshToken() {
    const limit = Number(localStorage.getItem('action_refresh_token') ?? 0) + 1;

    localStorage.setItem('action_refresh_token', `${limit}`);

    return limit;
  }
}
