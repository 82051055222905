import { ActionReducerMap, createSelector } from '@ngrx/store';

import { RouterReducer, RouterState } from './router/router.reducer';
import { AuthenticateReducer, AuthenticateState } from './authenticate/authenticate.reducer';
import { configReducer, IConfiglData } from './config/config.reducer';
import { IMediaData, mediaReducer } from './media/media.reducer';
import { IRolesUserAccess, rolesAccessReducer } from './roles/roles.reducer';

export interface State {
    router: RouterState;
    authenticate: AuthenticateState;
    config: IConfiglData,
    media: IMediaData,
    roles: IRolesUserAccess
};

export const reducersRoot: ActionReducerMap<State> = {
    router: RouterReducer,
    authenticate: AuthenticateReducer,
    config: configReducer,
    media: mediaReducer,
    roles: rolesAccessReducer
};

export const selectFeature = (state: State) => state;

//export const getConfigsInRouterState = createSelector(getAppState, (state: State) => state.router.configs);

export const getLoginProfileInAuthenticateState = createSelector(selectFeature, (state: State) => state.authenticate.loginProfile);
// export const getRolesInAuthenticateState = createSelector(getAppState, (state: State) => state.authenticate.roles);
// export const getRoleInAuthenticateState = createSelector(getAppState, (state: State) => state.authenticate.role);