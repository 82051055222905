import { createAction, props } from '@ngrx/store';
import { Role } from '../../../core/models';

enum AuthenticateActionType {
    SetLoginProfileInStore = '[Authenticate] Set Login Profile In Store',
    SetRolesInStore = '[Authenticate] Set Roles In Store',
    SetRoleInStore = '[Authenticate] Set Role In Store'
};

const setLoginProfileInStore = createAction(
    AuthenticateActionType.SetLoginProfileInStore,
    props<{ payload: { loginProfile: any } }>()
);

const setRolesInStore = createAction(
    AuthenticateActionType.SetRolesInStore,
    props<{ payload: { roles: Array<Role> } }>()
);

const setRoleInStore = createAction(
    AuthenticateActionType.SetRoleInStore,
    props<{ payload: { role: Role } }>()
);

export const authenticateAction = {
    setLoginProfileInStore, setRolesInStore, setRoleInStore
};