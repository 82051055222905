import { createAction, props } from '@ngrx/store';

enum RouterActionType {
    SetConfigsInStore = '[Router] Set Configs In Store'
};

const setConfigsInStore = createAction(
    RouterActionType.SetConfigsInStore,
    props<{ payload: { url: string, config: any } }>()
);

export const routerAction = { setConfigsInStore };




