import { createAction, props } from '@ngrx/store';

export interface IDirectoryTree {
  key: string | number;
  title: string;
  isLeaf: boolean;
  children: IDirectoryTree[]
}

export enum EMediaAction {
  LOAD = '[Media] load data',
  SETUP_ROOT = '[Media] setup root directory',
  DIRECTORY = '[Media] setup directory',
}

export const actionLoadMedia= createAction(
  EMediaAction.LOAD,
  props<any>()
);

export const actionSetupMediaRoot= createAction(
  EMediaAction.SETUP_ROOT,
  props<{ root: any }>()
);

export const actionSetupDirectoryMedia= createAction(
  EMediaAction.DIRECTORY,
  props<{ setup: IDirectoryTree[] }>()
);
