import { createReducer, on } from '@ngrx/store';


import { authenticateAction } from '../../actions';
import { Role } from '../../../core/models';

export const authenticateFeatureKey = 'authenticate';

export interface AuthenticateState {
    loginProfile : any;
    roles : Array<Role> | null;
    role : Role | null;
}

export const initialState: AuthenticateState = {
    loginProfile : null,
    roles : null,
    role : null,
};

export const AuthenticateReducer = createReducer(
    initialState,
    on(
        authenticateAction.setLoginProfileInStore,
        (state, action) => {
            return {
                ...state,
                loginProfile : action.payload.loginProfile
            };
        }
    ),
    on(
        authenticateAction.setRolesInStore,
        (state, action) => {
            return {
                ...state,
                roles : action.payload.roles
            };
        }
    ),
    on(
        authenticateAction.setRoleInStore,
        (state, action) => {
            return {
                ...state,
                role : action.payload.role
            };
        }
    )
);

